<template>
  <div class="myProfile_box scroll_box">
    <table>
      <tr>
        <td>{{ $t('common.field.accType') }}</td>
        <td>{{ accountType[clientProfileData.accountType] }}</td>
      </tr>
      <tr>
        <td>{{ $t('common.field.name') }}</td>
        <td>{{ clientProfileData.name }}</td>
      </tr>
      <tr>
        <td>{{ $t('common.field.emailAdd') }}</td>
        <td>{{ clientProfileData.emailAddress }}</td>
      </tr>
      <tr>
        <td>{{ $t('common.field.phone') }}</td>
        <td>{{ clientProfileData.phone }}</td>
      </tr>
      <tr>
        <td>{{ $t('common.field.dob') }}</td>
        <td>{{ clientProfileData.dateBirth }}</td>
      </tr>
      <tr>
        <td>{{ $t('common.field.nat') }}</td>
        <td>{{ clientProfileData.nationality }}</td>
      </tr>
      <tr>
        <td>{{ $t('common.field.country') }}</td>
        <td>{{ clientProfileData.country }}</td>
      </tr>
      <tr>
        <td>{{ $t('common.field.street') }}</td>
        <td>{{ clientProfileData.streetAddress }}</td>
      </tr>
      <tr>
        <td>{{ $t('common.field.suburbOrCity') }}</td>
        <td>{{ clientProfileData.suburb }}</td>
      </tr>
      <tr>
        <td>{{ $t('common.field.provinceOrState') }}</td>
        <td>{{ clientProfileData.state }}</td>
      </tr>
      <tr>
        <td>{{ $t('common.field.postcode') }}</td>
        <td>{{ clientProfileData.postcode }}</td>
      </tr>
      <tr>
        <td>{{ $t('common.field.empStat') }}</td>
        <td>{{ clientProfileData.employmentStatus }}</td>
      </tr>
      <tr>
        <td>{{ $t('common.field.annIncome') }}</td>
        <td>{{ clientProfileData.averageIncome }}</td>
      </tr>
      <tr>
        <td>{{ $t('common.field.save') }}</td>
        <td>{{ clientProfileData.savingsInvestment }}</td>
      </tr>
      <tr>
        <td>{{ $t('common.field.source') }}</td>
        <td>{{ clientProfileData.sourceOfFunds }}</td>
      </tr>
      <!-- <tr>
        <td>{{ $t('common.field.ipChangeWarning') }}</td>
        <td>
          <el-switch v-model="clientProfileData.ipChangeWarn" @change="changeIpWarningChange"></el-switch>
        </td>
      </tr> -->
    </table>
  </div>
</template>

<script>
import myProfileMixin from '@/mixins/page/myProfile';
// import { apiSubmitResetChangeIpWarn } from '@/resource';

export default {
  mixins: [myProfileMixin],
  data() {
    return {
      clientProfileData: {
        accountType: '',
        name: '',
        emailAddress: '',
        phone: '',
        dateBirth: '',
        nationality: '',
        country: '',
        streetAddress: '',
        suburb: '',
        state: '',
        postcode: '',
        employmentStatus: '',
        averageIncome: '',
        savingsInvestment: '',
        sourceOfFunds: ''
      }
    };
  },
  methods: {
    // changeIpWarningChange(value) {
    //   apiSubmitResetChangeIpWarn({ changeIpWarn: value }).then(resp => {
    //     if (resp.data.code == 0) {
    //       this.clientProfileData.ipChangeWarn = value;
    //     }
    //   });
    // }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/myProfile.scss';
</style>
